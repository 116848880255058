import React from "react";
import { graphql, Link, navigate } from "gatsby";
import useAuth from "../hooks/useAuth";

import ShopLayout from "../components/new-layout";
import Breadcrumbs from "../components/breadcrumbs";
import Product from "../components/product";

import { isBrowser } from "../utils/isBrowser";
import { convertToSlug } from "../utils/converters";

const ProductList = ({ data, pageContext }) => {
  const { state } = useAuth();
  const isAuthenticated =
    state !== undefined &&
    state.isLoggedIn &&
    state.isActive &&
    !state.isExpired;

  if (isBrowser && !isAuthenticated) {
    navigate("/");
    return null;
  }

  const products = data.allProduct.edges;
  const categories = data.allCategory.edges;

  const {
    currentPage,
    totalPages,
    relativePath,
    categoryId,
    genre,
    index,
    skip,
    limit,
    totalProducts,
  } = pageContext;

  let category = categories.find(({ node: c }) => c._id === categoryId);
  let byCategory = category === undefined ? false : true;
  let byGenre = genre === undefined ? false : true;

  let links = [
    ["/platform", "Home"],
    ["/store", "store"],
  ];

  if (byCategory) links.push([relativePath, category.node.name]);

  if (byGenre) links.push([relativePath, genre]);

  let availableSoonContent = (
    <div className="flex flex-col md:flex-row">
      <div className="flex-grow">
        <div className="flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between mb-6">
          <div className="text-gray-700">Available Soon.</div>
        </div>
      </div>
    </div>
  );

  let paginationContent = (
    <div className="flex justify-center text-xl space-x-2 items-center">
      {Array.from({ length: totalPages }).map((_, i) => (
        <Link
          key={i}
          to={i === 0 ? relativePath : `${relativePath}/page/${i + 1}`}
          className={`w-10 h-10 rounded-full leading-none font-extrabold flex items-center 
            justify-center shadow focus:outline-none ${
              i + 1 === currentPage
                ? "bg-pink-900 text-white"
                : "bg-gray-100 text-pink-700"
            }`}
        >
          {i + 1}
        </Link>
      ))}
    </div>
  );

  return (
    <ShopLayout>
      <Breadcrumbs links={links} />
      <div className="container md:pl-12 my-10">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mb-4">
          <h1 className="font-display text-2xl text-blue-900 capitalize">
            {byCategory ? category.node.name : byGenre ? genre : "Store"}
          </h1>
        </div>
        {products.length > 0 ? (
          <div className="flex flex-col md:flex-row">
            <div className="flex-grow">
              <div className="flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between mb-6">
                <div className="text-gray-700">
                  Showing{" "}
                  {`${index + 1} - ${
                    skip === 0
                      ? limit > totalProducts
                        ? totalProducts
                        : limit
                      : skip - 1
                  }`}{" "}
                  of {totalProducts}
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
                {products.map(({ node: product }) => (
                  <Product
                    product={product}
                    userGroups={state !== undefined ? state.groups : []}
                    key={product._id}
                  />
                ))}
              </div>
              {totalPages > 1 && paginationContent}
            </div>
            <div className="hidden md:block md:w-60 md:ml-12">
              <div className="font-display text-blue-900 text-xl mb-4">
                Categories
              </div>
              <ul className="text-gray-700 flex flex-col space-y-4 mb-8">
                {categories.map(({ node: categoryItem }) => (
                  <li className="flex items-center" key={categoryItem._id}>
                    <span
                      className={`w-3 h-3 rounded-full mr-4 ${
                        byCategory
                          ? categoryItem._id === category._id
                            ? "bg-fuschia-700"
                            : "bg-pink-700"
                          : "bg-pink-700"
                      }`}
                    ></span>
                    <Link
                      to={`/store/category/${convertToSlug(categoryItem.name)}`}
                    >
                      {categoryItem.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          availableSoonContent
        )}
      </div>
    </ShopLayout>
  );
};

export default ProductList;

export const query = graphql`
  query($skip: Int!, $limit: Int!, $categoryId: String, $genre: String) {
    allProduct(
      sort: { fields: [rankedByCategory___item___position], order: ASC }
      limit: $limit
      skip: $skip
      filter: {
        categories: { elemMatch: { _id: { eq: $categoryId } } }
        genre: { eq: $genre }
      }
    ) {
      edges {
        node {
          _id
          title
          price {
            type
            _id
            basePrice
            maxPrice
          }
          remoteImage {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          configurations {
            groups {
              _id
            }
            item {
              value {
                maxPrice
                basePrice
              }
            }
            updatedAt
          }
        }
      }
    }
    allCategory(filter: { type: { eq: "Main" } }) {
      edges {
        node {
          _id
          name
        }
      }
    }
  }
`;
